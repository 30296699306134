import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ApiLoaderService } from "./api-loader.service";
@Injectable({
    providedIn: 'root'
})

export class ApiLoaderInterceptorService implements HttpInterceptor
{
    count = 0;
    unloadedUrl: string = '';
    urls = ['procedure'];
    constructor(private loaderService: ApiLoaderService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.unloadedUrl = req.url;
        var lastSegment:any = this.unloadedUrl.split('/').pop();
		let endpoint = lastSegment.includes('?') ? lastSegment.split('?')[0] : lastSegment;
        if (this.urls.indexOf(endpoint) == -1)
        {
            
			this.showLoader();
			this.count++;
		}
		const customReq = req.clone({});
        return next.handle(customReq).pipe(tap((event: HttpEvent<any>) => {
            if(event instanceof HttpResponse)
            {
                this.hideLoader()
            }
        },
        (err: any) => {
            this.hideLoader();
        }
        ))
    }

    private showLoader(): void
    {
        // console.log('loading');
		this.loaderService.show();
	}

	private hideLoader(): void
    {
        // console.log('false loading');

		this.loaderService.hide();
	}

}