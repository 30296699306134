import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpAddressService {
  private dataSubject = new BehaviorSubject<string>('initial value');
  data$ = this.dataSubject.asObservable();
  private apiUrl = 'https://ircm.azurewebsites.net/ircm-api/auth/getIp/';

  constructor(private http: HttpClient) {
    // this.getIPAddress();
  }

  setData(value: string) {
    this.dataSubject.next(value);
  }

  getIPAddress(): void {
    this.http.get<{ ip: string }>(this.apiUrl).subscribe({
      next: (response:any) => {
        console.log('response',response);
        localStorage.setItem('system-ip', response);
      },
      error: (err:any) => {
        console.log('Error',err?.error?.text);
        localStorage.setItem('system-ip', err?.error?.text);
      }
    });
  }
}
