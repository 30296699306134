import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { appConfig } from 'src/environments/enviroment';

@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {
    private key = CryptoJS.enc.Utf8.parse(appConfig.EncryptKey);
    private iv = CryptoJS.enc.Utf8.parse(appConfig.EncryptIV);
    // Methods for the encrypt and decrypt Using AES
    encryptUsingAES256(data: any): string {
        try {
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return encrypted.toString();
        } catch (error) {
            console.error('Encryption error:', error);
            return ''; // or handle the error appropriately
        }
    }

    decryptUsingAES256(decString: any): string {
        try {
            let decrypted = CryptoJS.AES.decrypt(decString, this.key, {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Decryption error:', error);
            return ''; // or handle the error appropriately
        }
    }
    decodeJwtToken(token: string): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(atob(base64));
        } catch (error) {
            console.error('Error decoding JWT token:', error);
            return null;
        }
    }

}