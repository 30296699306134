import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiLoaderService } from './api-loader.service';
import { LoaderState } from './loader.state';

@Component({
  selector: 'app-api-loader',
  templateUrl: './api-loader.component.html',
  styleUrls: ['./api-loader.component.scss'],
})
export class ApiLoaderComponent {
  show: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private loaderService: ApiLoaderService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
        this._changeDetectorRef.detectChanges();
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
