import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { ApiLoaderComponent } from './api-loader/api-loader.component';
import { ApiLoaderService } from './api-loader/api-loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiLoaderInterceptorService } from './api-loader/api-loader.interceptor.service';
import { CommonModule } from '@angular/common';
import { ToTwelveHoursBasePipe } from './to-twelve-hours-base.pipe';

@NgModule({
  declarations: [ApiLoaderComponent, ToTwelveHoursBasePipe],
  providers: [
    ApiLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiLoaderInterceptorService,
      multi: true,
    },
  ],
  imports: [MaterialModule, CommonModule],
  exports: [MaterialModule, ApiLoaderComponent, ToTwelveHoursBasePipe],
})
export class ShareModule {}
 