import { Subject } from "rxjs";
import { LoaderState } from "./loader.state";
import { Injectable } from "@angular/core";

@Injectable()
export class ApiLoaderService
{
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    show()
    {
        this.loaderSubject.next(<LoaderState>{show:true});
    }

    hide()
    {
        this.loaderSubject.next(<LoaderState>{show:false});
    }
}