<div *ngIf="show" class="loader-content">
  <div class="loader-section">
    <div class="col-sm-12 text-center">
      <img
        class="mt-10"
        width="50"
        height="50"
        src="../../../assets/images/loading.gif"
        alt="Loader Image"
      />
    </div>
  </div>
</div>
