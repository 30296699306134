import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from 'src/environments/enviroment';
import { SearchCriteria } from '../models/search-criteria.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  protected apiURL: string = appConfig.apiUrl;

  constructor(public http: HttpClient) { }

  getpaymentConfiguration(practiceId: number) {
    return this.http.get(
      this.apiURL +
      'practice-payment-configuration/get-data-by-practice-id/' +
      practiceId,
    );
  }

  getinvoicedetail(patientId?: number) {
    return this.http.get(this.apiURL + 'payment/checkout/' + patientId);
  }
  stripeResponse(stripeRespon: any) {
    return this.http.post(
      this.apiURL + 'payment/charge/',
      stripeRespon,
      this.httpOptions,
    );
  }
  applePayResponse(stripeRespon: any) {
    return this.http.post(
      this.apiURL + 'payment/charge/',
      stripeRespon,
      this.httpOptions,
    );
  }



  getPatientPayment(searchCriteria: SearchCriteria) {
    return this.http.post(
      this.apiURL + 'billing/get-patient-payment',
      searchCriteria,
      this.httpOptions,
    );
  }
  getpatientpayinvoice(patientId: number) {
    return this.http.post(
      this.apiURL + 'payment/get-patient-invoice-data', patientId,
      this.httpOptions,
    );
  }
  getReceiptDetail(paymentId: string) {
    return this.http.post(
      this.apiURL + 'payment/patient-payment-receipt/', paymentId,
      this.httpOptions,
    );
  }
}
